import request from './api';

const deleteVideo = (id, notifyPerformer, reason, remarks) => {
  return request({
    url: `/performer/videos`,
    method: 'DELETE',
    params: {
      id,
      notifytoroom: notifyPerformer ? 1 : 0,
      reason,
      remarks,
    },
  });
};

const updateVideoData = ({
  id = 0,
  free = null,
  title = null,
  description = null,
  cost = null,
  galleryvideo = null,
  thumbnailposition = -1,
}) => {
  const params = new URLSearchParams({
    id: id,
  });

  if (free !== null) {
    params.set('data[free]', free);
  }

  if (title !== null) {
    params.set('data[title]', title);
  }

  if (description !== null) {
    params.set('data[description]', description);
  }

  if (cost !== null) {
    params.set('data[cost]', cost);
  }

  if (galleryvideo !== null) {
    params.set('data[gallery_video]', galleryvideo);
  }

  if (thumbnailposition > -1) {
    params.set('data[thumbnail_position]', thumbnailposition);
  }

  return request({
    url: `/performer/videos?${params.toString()}`,
    method: 'PUT',
    params: {},
  });
};

const getVideosList = ({
  idwebcammer = 0,
  uploadId = false,
  searchstr = '',
  paginationItems = 100,
  paginationPage = 1,
  sortField = 'uploaddate',
  sortDirection = 'DESC',
  status = null,
}) => {
  const params = new URLSearchParams({
    'sort[field]': sortField,
    "sort[order]'": sortDirection,
    'pagination[items]': paginationItems,
    'pagination[page]': paginationPage,
  });

  if (idwebcammer > 0) params.set('filters[idwebcammer]', idwebcammer);
  if (uploadId !== false) params.set('filters[uploadid]', uploadId);
  if (status) params.set('filters[status]', status);
  if (searchstr && searchstr.length > 1)
    params.set('filters[searchstr]', searchstr);

  return request({
    url: `/performer/videos?${params.toString()}`,
    method: 'GET',
  });
};

const getVideosWithDefaultThumbnail = ({
  free = 0,
  daysAgo = 30,
  perPage = 15,
  page = 1,
}) => {
  const params = new URLSearchParams({
    daysago: daysAgo,
    free: free,
    per_page: perPage,
    page,
  });

  return request({
    url: `/videos/hasdefaultthumbnail?${params.toString()}`,
    method: 'GET',
  });
};

const getProfileVideo = (idwebcammer) => {
  return request({
    url: `/performer/videos/getprofilevideo`,
    method: 'GET',
    params: {
      idwebcammer,
    },
  });
};

const setProfileVideo = (idVideo) => {
  return request({
    url: `/performer/videos/setprofilevideo`,
    method: 'PUT',
    params: {
      id: idVideo,
    },
  });
};

const validateVideo = (id, valid, notifyPerformer, reason, remarks) => {
  return request({
    url: `/admin/videos/validate`,
    method: 'GET',
    params: {
      id,
      valid,
      notifytoroom: notifyPerformer ? 1 : 0,
      reason,
      remarks,
      livelystaging: 0,
    },
  });
};

const reencodeVideo = (idVideo) => {
  return request({
    url: `/admin/videos/reencode`,
    method: 'GET',
    params: {
      id: idVideo,
    },
  });
};

const getVideoURL = (id, promo = 0) => {
  return request({
    url: `/performer/videos/videourl`,
    method: 'GET',
    params: {
      id,
      promo,
    },
  });
};

const getPendingValidation = () => {
  return request({
    url: `admin/videos/notvalidatevideos`,
    method: 'GET',
  });
};

const VideosService = {
  getVideosList,
  deleteVideo,
  updateVideoData,
  getProfileVideo,
  setProfileVideo,
  validateVideo,
  reencodeVideo,
  getVideoURL,
  getPendingValidation,
  getVideosWithDefaultThumbnail,
};

export default VideosService;

import { twMerge } from 'tailwind-merge';

import { ROOM_MODE } from '../../../../shared/constants';

import { useMonitor } from '../../../../contexts/monitor';

import Thumb from './Thumb';
import RoomMode from './RoomMode';
import RoomInfoIcons from './RoomInfoIcons';
import ActionButtons from './ActionButtons';
import RoomAdmins from './RoomAdmins';
import Nick from './Nick';
import Connected from './Connected';
import ConnectedAt from './ConnectedAt';
import Studio from './Studio';
import ProfileLinks from './ProfileLinks';
import GeoLocation from './GeoLocation';

import IconNew from '../../../../public/assets/images/icons/new.svg';

const List = ({
  performer = null,
  payingMode = null,
  handleDisplayMonitor = () => {},
}) => {
  const { modelId, canMonitorRoom } = useMonitor();

  return (
    <div
      className={twMerge(
        'flex gap-2 items-start border rounded-lg p-2 border-gray-200',
        modelId === performer.id && 'border-green-500 border-1 drop-shadow-md',
        !!payingMode &&
          performer.roomMode !== ROOM_MODE.FREECHAT &&
          'bg-orange-100'
      )}
      key={performer.id}
    >
      <Thumb
        onClick={(e) => {
          e.preventDefault();
          handleDisplayMonitor();
        }}
        thumb={performer.profile_photo}
      />

      <div className="flex flex-col h-full grow">
        <div className="flex items-center justify-between">
          <h2 className="flex gap-2 items-center text-lg font-semibold text-gray-900 truncate w-fit">
            <IconNew
              className={twMerge(
                'hidden',
                performer.new === 1 && 'w-10 h-10 inline-block animate-pulse'
              )}
            />
            <Nick performer={performer} displayMonitor={handleDisplayMonitor} />
            <RoomInfoIcons performer={performer} />
          </h2>

          <div className="flex gap-2 items-center">
            <RoomAdmins performer={performer} />
            <RoomMode performer={performer} showCounter={true} />
            <Connected
              too_much_time={performer.too_much_time}
              connected={performer.connected}
            />
          </div>
        </div>

        <div className="flex justify-between items-end">
          <span>
            <GeoLocation performer={performer} classNames="text-sm w-full" />
            <Studio performer={performer} />
            <ConnectedAt connected_at={performer.connected_at} />
            <ProfileLinks nick={performer.nick} />
          </span>
          <ActionButtons
            displayMonitor={handleDisplayMonitor}
            performer={performer}
          />
        </div>
      </div>
    </div>
  );
};

export default List;

import request from './api';

const getSegments = () => {
  return request({
    url: `/segments`,
    method: 'GET',
  });
};

const UtilsService = {
  getSegments,
};

export default UtilsService;

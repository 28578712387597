import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import StudiosService from '../../shared/studios-service';

import Select from '../../components/atoms/Select';
import { useAuth } from '../../contexts/auth';

const SelectorStudio = ({
  onChange: _onChange = () => {},
  selectedStudio: _selectedStudio = -1,
  labelInline = true,
  className = '',
  showLabel = true,
  hasDefaultValue = true,
  ...props
}) => {
  const { formatMessage: t } = useIntl();
  const { level } = useAuth();
  const [studies, setStudies] = useState([]);
  const [selectedStudio, setSelectedStudio] = useState(_selectedStudio);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (selectedValue, selectedLabel) => {
    setSelectedStudio(selectedValue);
    _onChange(selectedValue, selectedLabel);
  };

  useEffect(() => {
    setSelectedStudio(_selectedStudio);
  }, [_selectedStudio]);

  useEffect(() => {
    if (level !== 12) return () => {};
    setError(false);
    setLoading(true);
    //only Superadmins can get all studies
    StudiosService.getStudiosList({ active: 1 })
      .then((studies) => {
        setStudies(studies.list);
      })
      .catch((err) => {
        console.error('SelectorStudio', err);
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [level]);

  if (level !== 12) return null;

  return (
    <>
      {studies && (
        <Select
          label={showLabel ? t({ id: 'studio' }) : ''}
          className={twMerge('text-sm', className)}
          value={selectedStudio}
          labelInline={labelInline}
          onChange={(e) =>
            handleChange(
              e.target.value,
              e.target.options[e.target.selectedIndex].text
            )
          }
          disabled={loading}
          {...props}
        >
          <option value="-1" disabled={!hasDefaultValue}>
            {loading ? t({ id: 'loading' }) : t({ id: 'all_studios' })}
          </option>

          {studies.map((studio) => {
            return (
              <option key={studio.id} value={studio.id}>
                {studio.empresa}
              </option>
            );
          })}
        </Select>
      )}
      {error && (
        <span className="text-red-700 text-xs mt-0">
          {t({ id: 'error_loading_list' })}
        </span>
      )}
    </>
  );
};

export default SelectorStudio;

import { twMerge } from 'tailwind-merge';

import Tooltip from '../../../../components/Tooltip';

const Connected = ({ too_much_time, connected }) => {
  return (
    <Tooltip
      content={
        too_much_time ? 'Alerta duración de conexión' : 'Tiempo de conexión'
      }
    >
      <small
        className={twMerge(
          'text-sm text-gray-700 cursor-default',
          too_much_time && 'bg-yellow-400 rounded-lg p-1 text-xs'
        )}
      >
        {connected}h
      </small>
    </Tooltip>
  );
};

export default Connected;

const Radio = ({ label = null, ...props }) => {
  return (
    <div className="flex items-center">
      <input
        type="radio"
        className="w-4 h-4 text-teal-800 bg-gray-300 border-gray-500 focus:ring-teal-500 focus:ring-2"
        {...props}
      />
      <label className="ml-2 text-sm">{label}</label>
    </div>
  );
};

export default Radio;

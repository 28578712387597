import { useIntl } from 'react-intl';
import Link from 'next/link';

import Tooltip from '../../../../components/Tooltip';

import IconOC from '../../../../public/assets/images/icons/oc_logo.svg';

const ProfileLinks = ({ nick }) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className="flex gap-2">
      <Link
        href={`${process.env.NEXT_PUBLIC_OLECAMS_PROFILE_URL}${nick}`}
        target="_blank"
      >
        <Tooltip content={t({ id: 'olecams_profile' })}>
          <IconOC className="text-orange-600 w-5 h-5" />
        </Tooltip>
      </Link>

      <Link
        href={`${process.env.NEXT_PUBLIC_VCE_PROFILE_URL}${nick}`}
        target="_blank"
      >
        <Tooltip content={t({ id: 'vce_profile' })}>
          <img src="/assets/images/icons/vce_logo.png" width={12} height={16} />
        </Tooltip>
      </Link>
    </div>
  );
};

export default ProfileLinks;

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ListsService from '../../shared/list-service';
import SuperviseService from '../../shared/supervise-service';
import WebcammerService from '../../shared/webcamer-service';

import useInterval from '../../hooks/useInterval';

import Tooltip from '../Tooltip';
import Card from '../Card';
import Button from '../atoms/Button';

import IconExclamation from '../../public/assets/images/icons/exclamation.svg';

const OnlineListAlerter = () => {
  const { formatMessage: t } = useIntl();
  const [loading, setLoading] = useState(false);
  const [posibleRoomsWithConflict, setPosibleRoomsWithConflict] = useState([]);
  const [roomsWithConflict, setRoomsWithConflict] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);
  const REFRESH_TIME_IN_SECONDS = 35;

  const checkOnlineList = () => {
    setLoading(true);
    ListsService.checkOnlineList()
      .then((rooms) => {
        let today = new Date();
        let _roomsWithConflict = [];

        //Check if the room was in the previous request. If it was in the previous request, it may be a real warning because it hasn't been recovered
        rooms.forEach((room) => {
          if (posibleRoomsWithConflict.find((item) => item.id === room.id)) {
            if (!_roomsWithConflict.find((item) => item.id === room.id))
              _roomsWithConflict.push(room);
          }
        });
        setPosibleRoomsWithConflict(rooms);
        setRoomsWithConflict(_roomsWithConflict);

        setLastUpdate(
          today.getHours().toString().padStart(2, '0') +
            ':' +
            today.getMinutes().toString().padStart(2, '0') +
            ':' +
            today.getSeconds().toString().padStart(2, '0')
        );
      })
      .catch((e) =>
        console.error(
          'Ha ocurrido un error al intentar realizar el checkeo del listado online',
          e
        )
      )
      .finally(() => setLoading(false));
  };

  const resetRoom = (roomId) => {
    const toastId = toast.loading(t({ id: 'closing_room' }));

    const kickRoom = SuperviseService.kickRoom(roomId);
    const updateDBdata = WebcammerService.updateRoomData({
      online: 0, //set online = 0 in DB (offline)
      id: roomId,
    });

    Promise.all([kickRoom, updateDBdata])
      .then(() => {
        toast.update(toastId, {
          render: t({ id: 'room_closed' }),
          type: 'success',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        checkOnlineList();
      })
      .catch(() =>
        toast.update(toastId, {
          render: t({ id: 'room_closed_error' }),
          type: 'error',
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        })
      );
  };

  useEffect(() => {}, [roomsWithConflict]);

  useEffect(() => {
    checkOnlineList();
  }, []);

  useInterval(() => checkOnlineList(), REFRESH_TIME_IN_SECONDS * 1000);

  if (roomsWithConflict.length === 0) return null;

  return (
    <Card
      title={
        loading
          ? 'Cargando...'
          : `Hay salas online con información no concordante (${lastUpdate}h)`
      }
      Icon={IconExclamation}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2">
        {!loading &&
          roomsWithConflict &&
          roomsWithConflict.map((performer) => {
            return (
              <Room
                performer={performer}
                resetRoom={resetRoom}
                key={performer.id}
              />
            );
          })}
      </div>
    </Card>
  );
};

const Room = ({ performer = {}, resetRoom = () => {} }) => {
  return (
    <div
      className="flex gap-2 items-start border-2 border-yellow-500 rounded-lg p-2"
      key={performer.id}
    >
      <div className="flex flex-row w-full justify-between items-center text-sm cursor-default">
        <Tooltip
          key={performer.id}
          content={
            <>
              {performer.db_online_data_conflict && (
                <p>{'Conflicto en base de datos entre online y connected'}</p>
              )}
              {!performer.online && (
                <p>{'Offline en base de datos (online=0)'} </p>
              )}
              {!performer.db_online_data_conflict &&
                !performer.connected_to_ws && <p>{'Offline en WS'} </p>}
            </>
          }
        >
          <h2 className="font-semibold">
            {performer.nick} ({performer.id})
          </h2>
        </Tooltip>
        <Button
          onClick={() => {
            resetRoom(performer.id);
          }}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};
export default OnlineListAlerter;

import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import { SUPERADMIN_LEVEL } from '../../../../shared/constants';

import { useRealtime } from '../../../../contexts/realtime';
import { useAuth } from '../../../../contexts/auth';

import Tooltip from '../../../Tooltip';

import IconWarning from '../../../../public/assets/images/icons/exclamation.svg';

const RoomAdmins = ({ performer }) => {
  const { roster, timeInRoom, usersRoomMonitoringWarn } = useRealtime();
  const { id } = performer;
  const [admins, setAdmins] = useState([]);
  const { level } = useAuth();
  const { formatMessage: t } = useIntl();

  useEffect(() => {
    if (level !== SUPERADMIN_LEVEL) return;
    if (!roster) return;
    if (!roster.users) return;
    if (!id) return;
    let admin = roster.users.filter((element) => element.currentRoom === id);
    setAdmins(admin);
  }, [roster, id, level]);

  return (
    <>
      {admins.length > 0 && (
        <div className="mx-1 flex flex-wrap gap-1">
          {admins.map((admin) => {
            const totalTimeInRoom = timeInRoom(admin.monitoringTimestamp);
            return (
              <div
                key={admin.userId}
                className="text-xs items-center bg-stone-400 text-white p-1 rounded-lg cursor-default"
              >
                <Tooltip
                  content={t(
                    { id: 'time_in_room' },
                    { time: totalTimeInRoom.humanReadableFormat }
                  )}
                >
                  <span className="flex gap-1">
                    {usersRoomMonitoringWarn.includes(admin.id) && (
                      <IconWarning className="text-yellow-400 w-4 h-4 bg-black rounded-full" />
                    )}
                    {admin.nick}
                  </span>
                </Tooltip>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default RoomAdmins;

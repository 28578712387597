import { useEffect } from 'react';

import { HOME_GRID_MODES } from '../../../shared/constants';

import { useMonitor } from '../../../contexts/monitor';
import { useNotifications } from '../../../contexts/notifications';
import { useRoomsOnlineContext } from '../../../contexts/roomsOnlineList';

import GridView from './ListViews/Grid';
import ListView from './ListViews/List';
import ModalKickRoom from './ModalKickRoom';

const ListPerformersOnline = () => {
  const { displayMonitor, modelId, setPrevPerformer, setNextPerformer } =
    useMonitor();

  const { listPerformersOnline, currentViewMode } = useRoomsOnlineContext();

  const { roomNotified, setRoomNotified } = useNotifications();

  useEffect(() => {
    if (!roomNotified) return;
    let performer = listPerformersOnline.find(
      (element) => element.id === roomNotified
    );
    if (performer) displayMonitor(performer);
    setRoomNotified(null);
  }, [roomNotified, setRoomNotified]);

  useEffect(() => {
    if (!listPerformersOnline) return;
    if (!modelId) return;
    setNextPerformer(null);
    setPrevPerformer(null);
    //find current index
    const currentIndex = listPerformersOnline.findIndex(
      (element) => element.id === modelId
    );
    if (currentIndex < listPerformersOnline.length)
      setNextPerformer(listPerformersOnline[currentIndex + 1]);
    if (currentIndex > 0)
      setPrevPerformer(listPerformersOnline[currentIndex - 1]);
  }, [listPerformersOnline, modelId, setNextPerformer, setPrevPerformer]);

  return (
    <>
      {currentViewMode === HOME_GRID_MODES.GRID && <GridView />}
      {currentViewMode === HOME_GRID_MODES.LIST && <ListView />}
      <ModalKickRoom />
    </>
  );
};

export default ListPerformersOnline;

import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRealtime } from '../../../../contexts/realtime';

const Nick = ({ performer, displayMonitor }) => {
  const { roomPaused } = useRealtime();
  const [showPaused, setShowPaused] = useState(null);

  //set initial value for paused
  useEffect(() => {
    if (showPaused === null) {
      setShowPaused(Boolean(performer.paused));
    }
  }, [showPaused, performer.paused]);

  //update in real time showPaused
  useEffect(() => {
    if (roomPaused?.id !== performer.id) return; //check if is performer instance
    setShowPaused(Boolean(roomPaused.paused));
  }, [roomPaused, performer.id]);

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        displayMonitor(performer);
      }}
    >
      <span className={twMerge(Boolean(showPaused) && 'text-red-600')}>
        {performer.nick} <span className="text-xs">[{performer.id}]</span>
      </span>
    </button>
  );
};

export default Nick;

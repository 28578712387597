import useInterval from '../../hooks/useInterval';

import { useRealtime } from '../../contexts/realtime';

const TimeInRoomChecker = () => {
  const { checkUsersTimeInRoom } = useRealtime();
  const interval = 30 * 1000; //every 30 seconds

  useInterval(checkUsersTimeInRoom, interval);
};

export default TimeInRoomChecker;

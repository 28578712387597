import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { useAuth } from '../../contexts/auth';

import {
  SUPERADMIN_LEVEL,
  AUTHORIZED_USERS_SPECIAL_ACTIONS,
} from '../../shared/constants';

import Tooltip from '../Tooltip';
import Modal from '../Modal';
import Input from '../atoms/Input';
import Card from '../Card';

import IconPlugOff from '../../public/assets/images/icons/power_plug_off.svg';
import Button from '../atoms/Button';

const CloseAllRooms = ({ listRooms = null }) => {
  const { formatMessage: t } = useIntl();
  const { level, userId } = useAuth();

  const [listRoomsOnline, setListRoomsOnline] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [closingRooms, setClosingRooms] = useState(false);
  const [currentRoomClosing, setCurrentRoomClosing] = useState(-1);

  const closeRooms = () => setCurrentRoomClosing(0);
  const closeRoom = (roomIndex) => {
    let roomNick = listRoomsOnline[roomIndex].nick;
    SuperviseService.kickRoom(roomId)
      .catch(() =>
        toast.error(`${roomNick}: ${t({ id: 'room_closed_error' })}`)
      )
      .finally(() => {
        if (parseInt(roomIndex + 1) === parseInt(listRoomsOnline.length)) {
          //it is the end of the list
          setClosingRooms(false);
          setCurrentRoomClosing(-1);
          toast.success('All rooms closed');
          return false;
        } else {
          //Set next currentRoomClosing
          setCurrentRoomClosing((current) => current + 1);
        }
      });
  };

  useEffect(() => {
    if (currentRoomClosing > -1) {
      closeRoom(currentRoomClosing);
    }
  }, [currentRoomClosing]);

  useEffect(() => {
    if (closingRooms) {
      setConfirming(false);
      closeRooms();
    }
  }, [closingRooms]);

  useEffect(() => {
    if (listRooms && listRooms.length > 0 && listRoomsOnline === null)
      setListRoomsOnline(listRooms);
  }, [listRooms, listRoomsOnline]);

  if (
    level !== SUPERADMIN_LEVEL ||
    !AUTHORIZED_USERS_SPECIAL_ACTIONS.includes(userId)
  )
    return null;

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setConfirming(true);
        }}
      >
        <Tooltip content={t({ id: 'close_all_rooms' })} variant="red">
          <IconPlugOff className="text-white w-6 h-6 border bg-red-600" />
        </Tooltip>
      </button>

      {closingRooms && currentRoomClosing > -1 && listRoomsOnline && (
        <div className="absolute inset-0 bg-black/90 text-white text-lg flex flex-col gap-1 items-center justify-center z-50">
          <p>
            Sala {currentRoomClosing + 1} de {listRoomsOnline.length}{' '}
          </p>
          <p> Cerrando sala {listRoomsOnline[currentRoomClosing].nick} ...</p>
        </div>
      )}

      {confirming && (
        <ModalConfirming
          onOk={() => setClosingRooms(true)}
          onCancel={() => setConfirming(false)}
        />
      )}
    </>
  );
};

const ModalConfirming = ({ onOk = () => {}, onCancel = () => {} }) => {
  const [secureCode, setSecureCode] = useState('');

  const handleSuccess = () => {
    if (secureCode === 'Far$a') {
      onOk();
    } else {
      toast.warn('Código incorrecto');
    }
  };

  return (
    <Modal>
      <Card
        title="Forzar el cierre de todas las salas"
        className="max-w-[450px]"
      >
        <div className="flex flex-col gap-2">
          <span className="flex flex-col gap-2">
            <p className="font-bold text-sm">
              ¿Estás seguro de querer cerrar todas las salas activas?
            </p>
            <p className="text-sm">
              Introduzca el código de seguridad para realizar la acción.
            </p>
          </span>
          <Input.Text
            value={secureCode}
            onChange={(e) => setSecureCode(e.target.value)}
            placeholder="Introduzca código de seguridad"
          />
        </div>
        <div className="h-2" />
        <div className="flex gap-2">
          <span className="grow">
            <Button
              rounded
              fullWidth={true}
              minWidth={false}
              color={Button.VARIANT_RED}
              onClick={onCancel}
              type="outline"
            >
              Cancelar
            </Button>
          </span>
          <span className="grow">
            <Button
              rounded
              minWidth={false}
              fullWidth={true}
              color={Button.VARIANT_GREEN}
              onClick={(e) => {
                e.preventDefault();
                handleSuccess();
              }}
            >
              Sí, cerrar todas las salas
            </Button>
          </span>
        </div>
      </Card>
    </Modal>
  );
};

export default CloseAllRooms;

import { useIntl } from 'react-intl';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { SUPERADMIN_LEVEL } from '../../../../shared/constants';

import { useMonitor } from '../../../../contexts/monitor';
import { useAuth } from '../../../../contexts/auth';

import Tooltip from '../../../Tooltip';

import IconProfile from '../../../../public/assets/images/icons/account_details.svg';
import IconPlay from '../../../../public/assets/images/icons/play.svg';
import IconCloseRoom from '../../../../public/assets/images/icons/skull.svg';

const ActionButtons = ({ performer = {}, displayMonitor = () => {} }) => {
  const { formatMessage: t } = useIntl();
  const { level } = useAuth();
  const { modelId, setKickRoom, setModelId, canMonitorRoom } = useMonitor();

  if (!canMonitorRoom) return null;
  return (
    <div className="flex items-center gap-1">
      <Tooltip content={t({ id: 'peformer_profile' })}>
        <Link href={`/performers/${performer.id}`} target="_blank">
          <IconProfile className="w-5 h-5" />
        </Link>
      </Tooltip>

      <button>
        <Tooltip content={t({ id: 'live_video' })}>
          <IconPlay
            className={twMerge(
              'text-green-600 w-5 h-5',
              modelId === performer.id && 'text-green-800 animate-pulse'
            )}
            onClick={() => {
              if (modelId !== performer.id) displayMonitor(performer);
              if (modelId === performer.id) setModelId(null);
            }}
          />
        </Tooltip>
      </button>

      {level === SUPERADMIN_LEVEL && (
        <button>
          <Tooltip content={t({ id: 'close_room' })}>
            <IconCloseRoom
              className="text-black w-5 h-5"
              onClick={(e) => {
                setKickRoom(performer);
              }}
            />
          </Tooltip>
        </button>
      )}
    </div>
  );
};

export default ActionButtons;

import { useIntl } from 'react-intl';

import Tooltip from '../../../../components/Tooltip';

const ConnectedAt = ({ connected_at, translate }) => {
  const { formatMessage: t } = useIntl();

  return (
    <Tooltip content={`${connected_at} CEST`}>
      <p className="text-gray-700 text-sm">
        {t({ id: 'connected_at' })}: {connected_at.split(' ')[1]}h.
      </p>
    </Tooltip>
  );
};

export default ConnectedAt;

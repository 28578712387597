import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import ListsService from '../../shared/list-service';

import Select from '../../components/atoms/Select';
import { useAuth } from '../../contexts/auth';

const SelectorManager = ({
  selectedManager: _selectedManager = -1,
  studioId: _studioId = 0,
  onChange: _onChange = () => {},
  showLabel = true,
  ...props
}) => {
  const { formatMessage: t } = useIntl();
  const { level, idstudio } = useAuth();
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(_selectedManager);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (selectedValue, selecteLabel) => {
    setSelectedManager(selectedValue);
    _onChange(selectedValue, selecteLabel);
  };

  const getManagers = () => {
    setError(false);
    setLoading(true);
    ListsService.getUsers({ studioid: _studioId, limit: 500, active: 1 })
      .then((managers) => {
        setManagers(managers.list);
        setSelectedManager(_selectedManager);
      })
      .catch((err) => {
        console.error('SelectorManager', err);
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (_studioId <= 0) return;

    //Managers can`t select managers
    if (level === 2) return () => {};

    //if administrator then force to get only his managers
    if (level === 1) _studioId = idstudio;

    getManagers();
  }, [level, idstudio, _studioId]);

  //if studioId changes then reset selected manager
  useEffect(() => setSelectedManager(-1), [_studioId]);

  if (level === 2) return null;

  return (
    <>
      <Select
        label={showLabel ? t({ id: 'manager' }) : ''}
        className="text-sm"
        value={selectedManager}
        onChange={(e) =>
          handleChange(
            e.target.value,
            e.target.options[e.target.selectedIndex].text
          )
        }
        disabled={
          level === 2 || managers.length === 0 || _studioId <= 0 || loading
        }
        {...props}
      >
        <option value="-1">
          {loading ? t({ id: 'loading' }) : t({ id: 'all_managers' })}
        </option>
        {managers.map((manager) => {
          return (
            <option key={manager.id} value={manager.id}>
              {manager.nick}
            </option>
          );
        })}
      </Select>
      {error && (
        <span className="text-red-700 text-xs mt-0">
          {t({ id: 'error_loading_list' })}
        </span>
      )}
    </>
  );
};

export default SelectorManager;

import { twMerge } from 'tailwind-merge';

import { ROOM_MODE } from '../../../../shared/constants';

import { useMonitor } from '../../../../contexts/monitor';

import Thumb from './Thumb';
import RoomMode from './RoomMode';
import RoomInfoIcons from './RoomInfoIcons';
import ActionButtons from './ActionButtons';
import RoomAdmins from './RoomAdmins';
import ConnectedAt from './ConnectedAt';
import ProfileLinks from './ProfileLinks';
import GeoLocation from './GeoLocation';
import Studio from './Studio';
import Header from './Header';

const Grid = ({
  performer = null,
  payingMode = null,
  handleDisplayMonitor = () => {},
}) => {
  const { modelId, canMonitorRoom } = useMonitor();

  return (
    <div
      className={twMerge(
        'flex gap-2 items-start border rounded-lg p-2',
        modelId === performer.id && 'border-green-600 border-4 drop-shadow-md',
        modelId !== performer.id && 'border-gray-200',
        !!payingMode &&
          performer.roomMode !== ROOM_MODE.FREECHAT &&
          'bg-orange-100'
      )}
      key={performer.id}
    >
      <Thumb
        onClick={(e) => {
          e.preventDefault();
          handleDisplayMonitor();
        }}
        thumb={performer.profile_photo}
      />
      <div className="block w-full">
        <div className="flex flex-col h-full w-full">
          <Header
            performer={performer}
            handleDisplayMonitor={handleDisplayMonitor}
          />
          <Studio performer={performer} />
          <GeoLocation performer={performer} />
          <ConnectedAt connected_at={performer.connected_at} />
          <ProfileLinks nick={performer.nick} />
          <div className="flex">
            <RoomMode performer={performer} showCounter={true} />
            <RoomAdmins performer={performer} />
          </div>
        </div>

        <div className="flex justify-between items-center mt-3">
          <RoomInfoIcons performer={performer} />
          <ActionButtons
            displayMonitor={handleDisplayMonitor}
            performer={performer}
          />
        </div>
      </div>
    </div>
  );
};

export default Grid;

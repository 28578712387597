import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import Select from '../../components/atoms/Select';
import utilsService from '../../shared/utils-service';

const SelectorRoomType = ({
  onChange: _onChange = () => {},
  selectedRoomType: _selectedRoomType = -1,
  showLabel = true,
  ...props
}) => {
  const { formatMessage: t } = useIntl();
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [segments, setSegments] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSegments = () => {
    setLoading(true);
    utilsService
      .getSegments()
      .then((response) => setSegments(response))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    setSelectedRoomType(e.target.value);
    _onChange(e.target.value, e.target.options[e.target.selectedIndex].text);
  };

  useEffect(() => {
    setSelectedRoomType(_selectedRoomType);
  }, [_selectedRoomType]);

  useEffect(() => {
    getSegments();
  }, []);

  return (
    <Select
      label={showLabel ? t({ id: 'room_type' }) : ''}
      className="text-sm capitalize"
      value={selectedRoomType}
      onChange={handleChange}
      {...props}
      disabled={props.disabled || loading}
    >
      {loading && <option value="-1">{t({ id: 'loading' })}</option>}
      {!loading && (
        <>
          <option value="-1">{t({ id: 'all_content' })}</option>
          {segments.map((segment) => {
            {
              if (parseInt(segment.visible) === 1) {
                return (
                  <option key={segment.id} value={segment.id}>
                    {segment.name}
                  </option>
                );
              }
            }
          })}
        </>
      )}
    </Select>
  );
};

export default SelectorRoomType;

import React from 'react';
import { useMonitor } from '../contexts/monitor';

import { useAuth } from '../contexts/auth';

import { SUPERADMIN_LEVEL } from '../shared/constants';

import Layout from '../components/Layout';
import HomeAdvices from '../components/Home/HomeAdvices';
import OnlineListAlerter from '../components/Home/OnlineListAlerter';

import TimeInRoomChecker from '../components/RealTime/TimeInRoomChecker';
import PerformersOnlineList from '../components/Home/PerformersOnlineList';

const Home = () => {
  const { level } = useAuth();

  return (
    <Layout>
      <HomeAdvices />
      <div className="h-3" />
      {level === SUPERADMIN_LEVEL && (
        <>
          <OnlineListAlerter />
          <div className="h-3" />
        </>
      )}

      <PerformersOnlineList />
      <TimeInRoomChecker />
    </Layout>
  );
};

export default Home;

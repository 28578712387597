import { useIntl } from 'react-intl';

import Tooltip from '../../../Tooltip';

import IconToy from '../../../../public/assets/images/icons/toy.svg';
import IconPhone from '../../../../public/assets/images/icons/phone.svg';

const RoomInfoIcons = ({ performer = {} }) => {
  const { formatMessage: t } = useIntl();

  return (
    <div className="flex items-center gap-1">
      {!!performer.toy && (
        <Tooltip content={t({ id: 'toy_connected' })}>
          <IconToy className="fill-black text-pink-500 w-5 h-5" />
        </Tooltip>
      )}
      {!!performer.app && (
        <Tooltip content={t({ id: 'using_app' })}>
          <span className="flex items-center justify-center w-6 h-6 border border-teal-600 rounded-full bg-pink-600 text-[9px] font-bold text-white">
            app
          </span>
        </Tooltip>
      )}
      {Number(performer.device) === 1 && (
        <Tooltip content={t({ id: 'broadcast_from_mobile' })}>
          <span className="bg-gray-300 border border-gray-500 text-white w-6 h-6 rounded-full flex items-center">
            <IconPhone className="text-black w-6 h-6" />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default RoomInfoIcons;

import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';

import { useRoomsOnlineContext } from '../../contexts/roomsOnlineList';

const UsersCounter = () => {
  const { formatMessage: t } = useIntl();
  const [totalPrivateUsers, setTotalPrivateUsers] = useState(0);
  const [totalFreeUsers, setTotalFreeUsers] = useState(0);
  const [totalSpyUsers, setTotalSpyUsers] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const { listPerformersOnline } = useRoomsOnlineContext();

  const calcUsersCounter = () => {
    let privateUsers = 0;
    let freeUsers = 0;
    let spyUsers = 0;
    if (!listPerformersOnline || listPerformersOnline.length === 0) return;
    listPerformersOnline.forEach((element) => {
      if (element.roomMode === 'freechat' && parseInt(element.freeusers) > 0)
        freeUsers += parseInt(element.freeusers);

      if (element.roomMode !== 'freechat' && parseInt(element.users) > 0)
        privateUsers += parseInt(element.users);

      if (parseInt(element.spyusers) > 0)
        spyUsers += parseInt(element.spyusers);
    });

    setTotalPrivateUsers(privateUsers);
    setTotalFreeUsers(freeUsers);
    setTotalSpyUsers(spyUsers);
    setTotalUsers(freeUsers + privateUsers + spyUsers);
  };

  useEffect(() => {
    if (!listPerformersOnline) return;
    calcUsersCounter();
  }, [listPerformersOnline]);

  return (
    <div className="flex items-center gap-2 text-xs">
      <span className="px-2 py-1  border rounded border-blue-800 bg-blue-700 text-white">
        {t({ id: 'users' })}: <b>{totalUsers < 0 ? 0 : totalUsers}</b>
      </span>
      <span className="px-2 py-1  border rounded border-green-600 bg-green-400 text-black">
        Free: <b>{totalFreeUsers < 0 ? 0 : totalFreeUsers}</b>
      </span>
      <span className="px-2 py-1  border rounded border-orange-600 bg-orange-500 text-white">
        GC/VIP: <b>{totalPrivateUsers < 0 ? 0 : totalPrivateUsers}</b>
      </span>
      <span className="px-2 py-1 border rounded border-black bg-gray-600 text-white">
        {t({ id: 'spy_users' })}: <b>{totalSpyUsers < 0 ? 0 : totalSpyUsers}</b>
      </span>
    </div>
  );
};

export default UsersCounter;

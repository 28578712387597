import { twMerge } from 'tailwind-merge';

import Connected from './Connected';
import Nick from './Nick';

import IconNew from '../../../../public/assets/images/icons/new.svg';

const Header = ({ performer = {}, handleDisplayMonitor = () => {} }) => {
  return (
    <div className="flex items-center justify-between">
      <h2 className="flex gap-2 items-center text-lg font-semibold text-gray-900 truncate w-2/3">
        <Nick performer={performer} displayMonitor={handleDisplayMonitor} />
        <IconNew
          className={twMerge(
            'hidden',
            performer.new === 1 && 'inline-block animate-pulse'
          )}
        />
      </h2>
      <Connected
        too_much_time={performer.too_much_time}
        connected={performer.connected}
      />
    </div>
  );
};

export default Header;

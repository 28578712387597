const Thumb = ({ onClick = {}, thumb = '' }) => {
  if (thumb === '') return null;

  return (
    <button onClick={onClick} className="hidden md:inline-block">
      <img width="50" height="50" src={thumb} className="rounded-full" />
    </button>
  );
};

export default Thumb;

import Link from 'next/link';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import useInterval from '../../hooks/useInterval';

import ListsService from '../../shared/list-service';
import VideosService from '../../shared/videos-service';

import { useAuth } from '../../contexts/auth.js';

import {
  SUPERADMIN_LEVEL,
  VIDEOS_WITH_DEFAULT_THUMB_DAYS_AGO,
} from '../../shared/constants.js';

const HomeAdvices = () => {
  const [loadingPendingRooms, setLoadingPendingRooms] = useState(true);
  const [loadingPendingVideos, setLoadingPendingVideos] = useState(true);
  const [loadingPendingImages, setLoadingPendingImages] = useState(true);
  const [
    loadingVideosWithDefaultThumbnail,
    setLoadingVideosWithDefaultThumbnail,
  ] = useState(true);
  const [totalRoomsNotValidated, setTotalRoomsNotValidated] = useState(null);
  const [totalRoomsStandby, setTotalRoomsStandby] = useState(null);
  const [totalVideosPending, setTotalVideosPending] = useState(null);
  const [totalPendingImages, setTotalPendingImages] = useState(null);
  const [totalVideosWithDefaultThumbnail, setTotalVideosWithDefaultThumbnail] =
    useState(null);
  const interval = 5 * 60 * 1000; //refresh data every 5 minutes

  const { level } = useAuth();

  const getPerformersNotValidated = () => {
    setLoadingPendingRooms(true);
    ListsService.getPendingValidate({
      sortField: 'fecha_alta',
      sortOrder: 'DESC',
      paginationItems: 100,
      option: 'all',
    })
      .then((performersList) => {
        setTotalRoomsNotValidated(
          performersList.list.filter((item) => item.status === 'si').length
        );
        setTotalRoomsStandby(
          performersList.list.filter((item) => item.status === 'sb').length
        );
      })
      .finally(() => setLoadingPendingRooms(false));
  };

  const getVideosNotValidate = () => {
    setLoadingPendingVideos(true);
    VideosService.getPendingValidation()
      .then((videosList) => {
        let totalVideos = 0;
        videosList.list.map((item) => {
          totalVideos += item.total;
        });
        setTotalVideosPending(totalVideos);
      })
      .finally(() => setLoadingPendingVideos(false));
  };

  const getImagesPending = () => {
    setLoadingPendingImages(true);
    ListsService.getThumbsPendingValidation({})
      .then((imagesList) => setTotalPendingImages(imagesList.total))
      .finally(() => setLoadingPendingImages(false));
  };

  const getVideosWithDefaultThumbnail = () => {
    setLoadingVideosWithDefaultThumbnail(true);
    VideosService.getVideosWithDefaultThumbnail({
      free: 0,
      daysAgo: VIDEOS_WITH_DEFAULT_THUMB_DAYS_AGO,
      perPage: 1000,
      page: 1,
    })
      .then((data) => setTotalVideosWithDefaultThumbnail(data.total))
      .finally(() => setLoadingVideosWithDefaultThumbnail(false));
  };

  const getData = () => {
    getPerformersNotValidated();

    if (level === SUPERADMIN_LEVEL) {
      getVideosNotValidate();
      getImagesPending();
      getVideosWithDefaultThumbnail();
    }
  };

  useEffect(() => {
    //check items pending validation
    getData();
  }, [level]);

  useInterval(() => getData(), interval);

  return (
    <div className="flex gap-2 items-center text-black overflow-x-auto">
      <NoticeTag
        label="Hay modelos sin validar"
        counter={totalRoomsNotValidated}
        to="/performers/validation"
        className="bg-yellow-100 border-yellow-500"
        Dot={
          <span className="inline-block bg-yellow-500 border-yellow-600 border h-2 w-2 rounded-full"></span>
        }
        loading={loadingPendingRooms}
      />
      <NoticeTag
        label="Modelos en standby"
        counter={totalRoomsStandby}
        to="/performers/standby"
        className="bg-gray-100 border-gray-500"
        Dot={
          <span className="inline-block bg-gray-500 border-gray-600 border h-2 w-2 rounded-full"></span>
        }
        loading={loadingPendingRooms}
      />
      <NoticeTag
        label="Vídeos sin validar"
        counter={totalVideosPending}
        to="/videos?status=0"
        className="bg-orange-100 border-orange-500"
        Dot={
          <span className="inline-block bg-orange-500 border-orange-600 border h-2 w-2 rounded-full"></span>
        }
        loading={loadingPendingVideos}
      />
      <NoticeTag
        label="Thumbs sin validar"
        counter={totalPendingImages}
        to="/performers/thumbspending"
        className="bg-orange-100 border-orange-500"
        Dot={
          <span className="inline-block bg-orange-500 border-orange-600 border h-2 w-2 rounded-full"></span>
        }
        loading={loadingPendingImages}
      />

      <NoticeTag
        label="Videos con default thumbnail"
        counter={totalVideosWithDefaultThumbnail}
        to="/videos/withDefaultThumb"
        className="bg-blue-200 border-blue-600"
        Dot={
          <span className="inline-block bg-blue-800 border-blue-600 border h-2 w-2 rounded-full"></span>
        }
        loading={loadingVideosWithDefaultThumbnail}
      />
    </div>
  );
};

const NoticeTag = ({
  label,
  counter = null,
  to = '#',
  className,
  Dot = null,
  loading = false,
}) => {
  if (!counter || counter === 0) return null;

  return (
    <Link
      href={to}
      className={twMerge(
        'rounded border px-2 py-1 text-sm whitespace-nowrap flex gap-1 items-center',
        className
      )}
    >
      {Dot && Dot}
      {label} {counter && counter}
    </Link>
  );
};

export default HomeAdvices;

import { useRoomsOnlineContext } from '../../../../contexts/roomsOnlineList';
import { useMonitor } from '../../../../contexts/monitor';

import Tooltip from '../../../../components/Tooltip';
import Room from '../Room/';

const GridView = () => {
  const { listPerformersOnline } = useRoomsOnlineContext();
  const { displayMonitor, setModelId, modelId, setKickRoom } = useMonitor();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3  gap-2">
      {listPerformersOnline.map((performer) => {
        return (
          <Room
            performer={performer}
            grid={true}
            displayMonitor={displayMonitor}
            key={performer.id}
            setKickRoom={setKickRoom}
            setModelId={setModelId}
            modelId={modelId}
          />
        );
      })}
      <Tooltip />
    </div>
  );
};

export default GridView;

import { useIntl } from 'react-intl';
import { useMonitor } from '../../../contexts/monitor';

import ModalAlert from '../../ModalAlert';

const ModalKickRoom = () => {
  const { formatMessage: t } = useIntl();
  const { forceCloseRoom, kickRoom, setKickRoom } = useMonitor();

  if (!kickRoom) return null;
  return (
    <ModalAlert
      title={t({ id: 'close_room' })}
      labelSuccess={t({ id: 'close' })}
      description={t(
        {
          id: 'close_confirmation',
        },
        { nick: kickRoom.nick }
      )}
      handleCancel={() => {
        setKickRoom(null);
      }}
      handleSuccess={() => {
        forceCloseRoom(kickRoom.id);
      }}
    />
  );
};

export default ModalKickRoom;

import { useRoomsOnlineContext } from '../../../../contexts/roomsOnlineList';
import { useMonitor } from '../../../../contexts/monitor';

import Tooltip from '../../../../components/Tooltip';
import Room from '../Room/';

const ListView = () => {
  const { listPerformersOnline } = useRoomsOnlineContext();
  const { displayMonitor, setModelId, modelId, setKickRoom } = useMonitor();

  return (
    <div className="w-full flex flex-col gap-2">
      {listPerformersOnline.map((performer) => {
        return (
          <Room
            performer={performer}
            grid={false}
            displayMonitor={displayMonitor}
            key={performer.id}
            setKickRoom={setKickRoom}
            setModelId={setModelId}
            modelId={modelId}
          />
        );
      })}

      <Tooltip />
    </div>
  );
};

export default ListView;

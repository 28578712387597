import request from './api';

function getStudiosList({
  sortfield = 'empresa',
  sortorder = 'asc',
  paginationitems = 500, //items per page
  paginationpage = 1, //page
  search = null,
  active = null,
}) {
  const params = new URLSearchParams({
    'sort[sort]': sortfield,
    'sort[direction]': sortorder,
    paginationitems,
    paginationpage,
  });

  if (search && search.length > 1) params.set('studioname', search);
  if (active) params.set('active', active);

  return request({
    url: `admin/studios?${params.toString()}`,
    method: 'GET',
  });
}

function getStudio(id) {
  return request({
    url: `admin/studio?id=${id}`,
    method: 'GET',
  });
}

function updateStudio(studioData) {
  return request({
    url: 'admin/studio',
    data: studioData,
    method: 'PUT',
  });
}

function addStudio(studioData) {
  return request({
    url: 'admin/studios',
    data: studioData,
    method: 'POST',
  });
}

const StudiosService = {
  getStudiosList,
  updateStudio,
  addStudio,
  getStudio,
};

export default StudiosService;

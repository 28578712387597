import { twMerge } from 'tailwind-merge';

const GeoLocation = ({ performer = null, classNames = '' }) => {
  if (!performer) return null;
  return (
    <p className={twMerge('text-xs font-semibold truncate w-4/5', classNames)}>
      {performer.broadcastCountry} - {performer.broadcastCity}
    </p>
  );
};

export default GeoLocation;

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { HOME_GRID_MODES, ROOM_MODE } from '../../../../shared/constants';
import Logger from '../../../../shared/logger';

import { useMonitor } from '../../../../contexts/monitor';
import { useRoomsOnlineContext } from '../../../../contexts/roomsOnlineList';
import { useRealtime } from '../../../../contexts/realtime';

import Grid from './Grid';
import List from './List';

const logger = new Logger('Room');
const Room = ({ performer, displayMonitor }) => {
  const router = useRouter();
  const { id, users } = performer;
  const { userCounter, rMode } = useRealtime();
  const { canMonitorRoom } = useMonitor();
  const [payingMode, setPayingMode] = useState(false);

  const { refreshUsersOnlineCounters, currentViewMode } =
    useRoomsOnlineContext();

  useEffect(() => {
    if (users > 0) {
      setPayingMode(true);
    } else {
      setPayingMode(false);
    }
  }, [users]);

  useEffect(() => {
    if (!rMode) return;
    if (rMode.id !== id) return;
    if (rMode.key === 'roomMode' && rMode.value === ROOM_MODE.FREE) {
      setPayingMode(false);
    }
  }, [rMode, id]);

  useEffect(() => {
    if (!userCounter) return;
    if (userCounter.id !== id) return;

    refreshUsersOnlineCounters(
      userCounter.value,
      userCounter.id,
      userCounter.key
    );

    if (userCounter.key === 'paying') {
      if (userCounter.value > 0) {
        setPayingMode(true);
      } else {
        setPayingMode(false);
      }
    }
  }, [userCounter, id]);

  const handleDisplayMonitor = () => {
    if (canMonitorRoom) {
      displayMonitor(performer);
      return;
    }
    router.push(`/performers/${performer.id}`);
  };

  return (
    <>
      {currentViewMode === HOME_GRID_MODES.GRID && (
        <Grid
          performer={performer}
          payingMode={payingMode}
          handleDisplayMonitor={handleDisplayMonitor}
        />
      )}
      {currentViewMode === HOME_GRID_MODES.LIST && (
        <List
          performer={performer}
          payingMode={payingMode}
          handleDisplayMonitor={handleDisplayMonitor}
        />
      )}
    </>
  );
};

export default Room;
